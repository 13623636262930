<script>
import Layout from "../../layouts/main";

import HorizontalMenu from "../../../components/horizontal-navbar-2.vue";
import DevicesMap from "./devices-map";

import {
  layoutComputed,
  authComputed,
  layoutMethods,
  devicesMethods,
  provincesMethods,
  statesMethods,
  causesMethods,
  actionsMethods,
  vaccintypesMethods,
  devicesComputed,
  provincesComputed,
  statesComputed,
  causesComputed,
  actionsComputed,
  vaccintypesComputed
} from "@/state/helpers";
/**
 * Devices component
 */
export default {
  page: {
    title: "Storage",
    meta: [{ name: "Storage" }],
  },
  components: {
    Layout,
    HorizontalMenu,
    DevicesMap,
  },
  mounted() {
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "hidden";
    this.changeMenuType();
  },
  created() {
    this.retrieveProvinces({}).then(async () => {
      await this.retrieveStates();
      await this.retrieveCauses();
      await this.retrieveActions();
      await this.retrieveVaccintypes();
    });
    //this.retrieveDevices();
  },
  data() {
    return {
      title: "Storage",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Storage",
          active: true,
        },
      ],

      loaderStatus: false,

      devicesData: [],
      provincesData: [],
      statesData: [],
      causesData: [],
      actionsData: [],
      vaccintypesData: [],
      route: "/",
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,
    ...provincesComputed,
    ...statesComputed,
    ...causesComputed,
    ...actionsComputed,
    ...vaccintypesComputed,
    userRole() {
      return this.loggedIn.user.role;
    },
  },
  methods: {
    ...layoutMethods,
    ...devicesMethods,
    ...provincesMethods,
    ...statesMethods,
    ...causesMethods,
    ...actionsMethods,
    ...vaccintypesMethods,
    changeMenuType() {
      this.changeLeftSidebarType({ leftSidebarType: "icon" });
    },
    retrieveProvinces({pass}) {
      if (this.getProvinces.results && !pass) {
        this.provincesData = this.getProvinces.results;
        return Promise.resolve(this.provincesData);
      } else {
        this.loaderStatus = true;
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          this.loaderStatus = false;
          return provinces;
        });
      }
    },
    retrieveDevices() {
      this.getDevicesBy({ limit: 10000 });
    },
    retrieveStates() {
      if (this.getStates.results) this.statesData = this.getStates.results;
      else
        return this.getStatesBy({ limit: 100 }).then((states) => {
          return states;
        });
    },
    retrieveVaccintypes() {
      if (this.getVaccintypes.results) this.vaccintypesData = this.getVaccintypes.results.filter(obj => obj.name != "Covid (1/2)");
      else
        return this.getVaccintypesBy({ limit: 100 }).then((vaccintypes) => {
          return vaccintypes;
        });
    },
    retrieveCauses() {
        return this.getCausesBy({ limit: 100, type: 1 }).then((causes) => {
          return causes;
        });
    },
    retrieveActions() {
      if (this.getActions.results) this.actionsData = this.getActions.results;
      else
        return this.getActionsBy({ limit: 100 }).then((actions) => {
          return actions;
        });
    },
  },
  watch: {
    getDevices(newVal) {
      let devicesFiltred = [];
      if (newVal.results) {
        newVal.results.map((obj) => {
          //if(obj.lat > 20 && obj.lat < 36 && obj.lng > -17 && obj.lng < -1){
          devicesFiltred.push(obj);
          //}
        });
      }

      this.devicesData = devicesFiltred;
    },
    getProvinces(newVal) {
      this.provincesData = newVal.results;
    },
    getStates(newVal) {
      this.statesData = newVal.results;
    },
    getCauses(newVal) {
      this.causesData = newVal.results;
    },
    getActions(newVal) {
      this.actionsData = newVal.results;
    },
    getVaccintypes(newVal) {
      this.vaccintypesData = newVal.results.filter(obj => obj.name != "Covid (1/2)");
    },
  },
  async beforeDestroy() {
    await this.retrieveProvinces({pass: true});
    var root = document.getElementsByTagName("html")[0];
    root.style.overflow = "visible";
    this.changeLeftSidebarType({ leftSidebarType: "light" });
  },
};
</script>

<template>
  <Layout>
    <Horizontal-menu class="horiMenu" v-bind:page="route" />
    <DevicesMap
      v-bind:devices="devicesData"
      :provinces="provincesData"
      :causes="causesData"
      :actions="actionsData"
      :states="statesData"
      :vaccintypes="vaccintypesData"
      :loader="loaderStatus"
    />
  </Layout>
</template>
<style scoped>
@media (min-width: 761px) {
  div /deep/ .footer {
    display: none;
  }
  div /deep/ #page-topbar {
    display: none;
  }
  div /deep/ .vertical-menu {
    display: none;
  }
}

@media (max-width: 760px) {
  .horiMenu {
    display: none;
  }
}

div /deep/ .container-fluid {
  padding: 0px;
}
div /deep/ .page-content {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
div /deep/ .main-content {
  margin-left: 0px;
}
</style>